<template>
  <div
    class="min-h-[calc(100vh-80px)] py-10 sm:min-h-[75vh] bg-gray-100 flex flex-col justify-center sm:px-4"
  >
    <div class="w-full md:w-[80%] mx-auto">
      <div class="flex flex-col justify-center items-center" data-aos="fade-down" data-aos-duration="1500">
        <div class="uppercase pt-10 tracking-widest text-gray-400 mb-2">
          Our services
        </div>
        <div class="md:tracking-wide big-title font-bitter text-3xl text-[#581845] font-bold mb-10">
          Solutions we provide
        </div>
      </div>
      <div
        class="gap-10 grid md:grid-cols-3 py-8 justify-between"
        :class="`[&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']`"
      >
        <div
          class="min-w-[300px] md:min-w-full relative bg-white pb-8"
          v-for="item in services"
          :key="item.icon"
          data-aos-easing="linear"
          data-aos="zoom-in-up"
          data-aos-duration="1500"
        >
          <div
            class="flex flex-col items-center"
          >
            <div class="h-[210px] bg-white">
              <img
                v-bind:src="require(`@/assets/services/${item.icon}.png`)"
                alt=""
                srcset=""
                class="h-full w-full object-contain"
              />
            </div>
            <div class="font-bold text-center my-5 text-gray-600 text-[20px] px-4">
              {{ item.title }}
            </div>
            <div class="leading-7 text-gray-500 text-center text-[15px] px-4">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      services: [
        {
          icon: "inventory",
          title: "Smart Inventory Management",
          description:
            "Say goodbye to overstocking and stockouts. We help you to optimize inventory levels based on sales data, saving you money and ensuring you always have what customers need.",
        },
        {
          icon: "pos",
          title: "All-in-One POS System",
          description:
            "Simplify your in-store operations with Hova POS, our powerful and user-friendly Point-of-Sale system. Manage sales, track inventory, and accept payments seamlessly.",
        },
        {
          icon: "data",
          title: "Powerful Data Analysis & Visualization",
          description:
            "Unlock the hidden potential within your business data. We help you to transform complex data into clear, actionable insights you can easily understand.",
        },
        
        // {
        //   icon: "cashback",
        //   title: "Customer Analytics",
        //   description:
        //     "Understanding customer behavior to personalize experiences and increase loyalty.",
        // },
        // {
        //   icon: "monitor",
        //   title: "24/7 Monitoring",
        //   description:
        //     "Easily monitor your business information for security and stability, ensuring peace of mind while you focus on growth.",
        // },
        // {
        //   icon: "performance",
        //   title: "Best Performance",
        //   description:
        //   "Optimize financial management with HovaStore's advanced tech and algorithms, empowering swift, informed decisions.",
        // },
        // {
        //   icon: "installment",
        //   title: "Payment Installments Management",
        //   description:
        //   "Effortlessly handle and manage payment installments for streamlined financial operations.",
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
