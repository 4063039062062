<template>
  <div id="about" class="bg-gray-50 flex items-center">
    <div class="grid md:gap-10 fixed-bg">
      <div
        class="h-full flex flex-col justify-center md:w-[50%] w-[85%] mx-auto py-10"
        data-aos-easing="linear"
        data-aos="zoom-in-up"
        data-aos-duration="1500"
      >
        <div class="uppercase tracking-widest text-gray-400 mb-5 sm:hidden">about us</div>
        <div class="mb-8" v-for="item in items">
          <div class="font-bold text-3xl text-white big-title"><i class="far fa-eye mr-5"></i>{{ item.title }}</div>
          <div class="leading-8 mt-3 text-gray-400 text-justify sm:text-center">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HwAbout",
  data() {
    return {
      items: [
        {
          title: "Our mission",
          content:
            "To be the leading provider of retail analytics in African markets, continually innovating and advancing our solutions to help businesses thrive and achieve their goals.",
        },
        {
          title: "Our vision",
          content: `HOVA AI envisions a future where small
          and medium enterprises in Africa's
          retail industry have access to innovative
          financial technology solutions that
          formalize their businesses, enhance
          their competitiveness, and increase
          their access to financial services,
          ultimately driving economic growth and
          development across the continent.`,
                  },
      ],
    };
  },
};
</script>

<style lang="scss">
.fixed-bg {
  background-image: url("@/assets/about.png");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
