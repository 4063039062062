<template>
  <div class="h-screen flex items-center bg-main">
    <div class="grid md:grid-cols-2 sm:px-4 h-full mx-auto relative overflow-hidden">
      <div class="big-title text-white xs:mt-5 sm:visible md:hidden">
          Unlock Your Potential with Data-Driven Insights
      </div>
      <div
        class="w-full flex flex-col justify-center md:pl-[20%] md:pr-[5%] px-[5%]"
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div class="big-title text-white xs:mt-5 md:visible sm:hidden">
          Unlock Your Potential with Data-Driven Insights
        </div>
        <div
          class="my-10 sm:my-4 sm:absolute sm:left-0 leading-8 w-full md:w-[90%] text-gray-400 sm:text-justify"
        >
          Revolutionize your business and unlock your full potential with our
          cutting-edge platform designed for African SMEs. Say goodbye to
          guesswork and hello to data-driven success. Achieve your business
          goals with our comprehensive retail analytics.
        </div>
      </div>
      <div
        class="sm:mt-10 absolute right-[-10%] sm:top-[25%] top-[5%]"
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <img src="@/assets/insight.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HwOnboard",
  props: {
    msg: String,
  },
  methods: {
    scrollToEl(index) {
      const element = document.querySelector(index);

      this.$smoothScroll({
        scrollTo: element,
        duration: 1500,
        offset: -70,
        updateHistory: true,
      });
    },
  }
};
</script>

<style scoped lang="scss">
.bg-main {
  background: linear-gradient(180deg, #571845 10%, #171A1F 100%);
}
</style>
