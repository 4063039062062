<template>
  <div
    class="bg-gray-50 min-h-[calc(100vh-80px)] flex items-center sm:px-4 sm:min-h-[75vh]"
  >
    <div class="w-full md:w-[60%] xl:w-[50%] mx-auto my-10">
      <div class="" data-aos="fade-down" data-aos-duration="1500">
        <div class="uppercase tracking-widest text-gray-400 mb-2">
          Customer Reviews
        </div>
        <div class="md:tracking-wide big-title font-bitter text-left text-3xl text-[#581845] font-bold mb-10">
          What our customers are saying?
        </div>
      </div>

      <div class="pt-10 sm:pt-5 flex justify-center items-center">
        <Swiper
          ref="swiperContainer"
          :slidesPerView="isMobile ? 2 : 1"
          :spaceBetween="60"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          loop="true"
          :modules="modules"
        >
          <SwiperSlide v-for="(item, i) in testimonies" :key="i">
            <div class="">
              <i class="text-gray-300 fas fa-quote-left text-[50px]"></i>
              <div class="mt-4 text-justify text-gray-600">
                {{ item.content }}
              </div>
            </div>

            <div class="flex items-center justify-between font-bold mt-5">
              <div class="flex items-center">
                <div
                  class="w-8 h-8 bg-[#581845] rounded-full flex items-center justify-center text-white mr-4"
                >
                  {{ item.name.charAt(0) }}
                </div>
                <span class="font-medium">{{ item.name }}</span>
              </div>
              <div class="">
                <i class="fas fa-store-alt mr-4 text-[#581845]"></i
                ><span class="font-medium">{{ item.shop }}</span>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        class="flex gap-2 justify-center mt-10"
        v-if="testimonies.length > 2"
      >
        <button
          @click="handlePrev"
          class="w-7 h-7 flex justify-center items-center"
        >
          <i class="fas fa-angle-left"></i>
        </button>
        <button
          @click="handleNext"
          class="w-7 h-7 flex justify-center items-center"
        >
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, ref, computed } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, EffectFade, Pagination, Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css";

export default defineComponent({
  name: "_header",
  components: { Swiper, SwiperSlide },

  data() {
    return {
      testimonies: [
        {
          name: "Joshua B.",
          shop: "Alimentation La grace",
          content: `As a business owner in Kigali, I have found HovaStore App to be a game changer. The app simplifies financial record keeping and provides an accurate system for tracking business finances. It's perfect for small business owners who want a hassle-free way to manage their finances. I highly recommend HovaStore App!`,
        },
        {
          name: "Aline K.",
          shop: "Taifa Boutique",
          content: `We are thrilled to have discovered HovaStore App! It has revolutionized the way we manage our finances at Taifa Boutique. With its user-friendly interface and efficient system, we can easily keep track of our sales, expenses, and profits. This app is a game changer for any business owner who wants to simplify their financial record keeping. Thank you, Hova AI!".`,
        },
      ],
    };
  },
  setup() {
    const screen = ref(window.innerWidth);
    const swiperContainer = ref(null);

    const handleNext = () => {
      swiperContainer.value.$el.swiper.slideNext();
    };

    const handlePrev = () => {
      swiperContainer.value.$el.swiper.slidePrev();
    };
    watchEffect(() => {
      window.addEventListener("resize", () => {
        screen.value = window.innerWidth;
      });
    });

    const isMobile = computed(() => screen.value > 768);

    return {
      screen,
      modules: [Autoplay, EffectFade, Pagination, Navigation],
      handleNext,
      handlePrev,
      swiperContainer,
      isMobile,
    };
  },
});
</script>

<style lang="scss" scoped></style>
